import URLSearchParams from './classes/URLSearchParams.js'
import FormData from './classes/FormData.js'

/**
 * Determine if we're running in a standard browser environment
 *
 * This allows axios to run in a web worker, and react-native.
 * Both environments support XMLHttpRequest, but not fully standard globals.
 *
 * web workers:
 *  typeof window -&gt; undefined
 *  typeof document -&gt; undefined
 *
 * react-native:
 *  navigator.product -&gt; 'ReactNative'
 * nativescript
 *  navigator.product -&gt; 'NativeScript' or 'NS'
 *
 * @returns {boolean}
 */
const isStandardBrowserEnv = (() =&gt; {
  let product;
  if (typeof navigator !== 'undefined' &amp;&amp; (
    (product = navigator.product) === 'ReactNative' ||
    product === 'NativeScript' ||
    product === 'NS')
  ) {
    return false;
  }

  return typeof window !== 'undefined' &amp;&amp; typeof document !== 'undefined';
})();

export default {
  isBrowser: true,
  classes: {
    URLSearchParams,
    FormData,
    Blob
  },
  isStandardBrowserEnv,
  protocols: ['http', 'https', 'file', 'blob', 'url', 'data']
};
